<template>
    <div>
        <v-autocomplete
            v-model="esteMed"
            :items="listaMedicamentos"
            :loading="loadingMeds"
            label="Medicamento"
            :search-input.sync="queryMed"
            item-text="concepto"
            item-value="id"
            no-filter
            rounded
            filled
            dense
            clearable
            return-object
        >
            <!-- El template para la lista de los datos -->
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-tooltip bottom color="red" v-if="data.item.mar == 1">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    x-small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red lighten-2"
                                >
                                    mdi-square
                                </v-icon>
                            </template>
                            <span>Medicamento de alto riesgo</span>
                        </v-tooltip>
                        ({{data.item.clave}}) {{data.item.concepto}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.especificacion}}</v-list-item-subtitle>
                </v-list-item-content>
            </template>


            <!-- El template para el control de paginate -->
            <template v-slot:append-item>
                <v-pagination
                    v-model="page"
                    circle
                    :length="pageLength"
                    total-visible="7"
                ></v-pagination>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    props:{
        medicamento:Object,
        tipoInsumo:Number,
        activo:Number,
    },
    data:()=>({
        loadingMeds:false,
        params:{},

        page:1,
        total:0,
        queryMed:"",

        listaMedicamentos:[],
        //pageLength:10,
    }),
    methods:{
        async cargaMedicamentos(/*query*/){

            if (!this.loadingMeds){
                if (this.queryMed && this.queryMed.length >= 3){

                    this.loadingMeds = true

                    if(this.activo){
                        this.params.activo = 1
                    }

                    if(this.tipoInsumo){
                        this.params.tipoInsumo = this.tipoInsumo
                    }

                    //this.params.tipoInsumo = this.tipoInsumo,
                    //this.params.activo = this.activo,
                    this.params.query = this.queryMed
                    this.params.page = this.page
                    this.params.start = (this.page -1) * 5
                    this.params.limit = 5

                    try {
                        var resp = await this.$http.get('/comi/listMedicamentoCb',{
                            // Usar esto para cuando la anotacion SECURE de modelo apunta SOLO A ANÓNIMO
                            params:this.params,
                        })
                        //.then(response =>{
                        this.listaMedicamentos = resp.data.root
                        this.total = resp.data.total
                        this.loadingMeds = false

                        //console.log(me.itemsUnidades);
                        //})
                    } catch(err) {
                        //console.log(err)
                        this.loadingMeds = false
                        this.$store.dispatch('security/revisaError',err)
                    }
                }
            }
        },
    },
    computed:{
        pageLength(){
            return (Math.ceil(this.total / 5));
        },
        esteMed:{
            get(){return this.medicamento},
            set(v){this.$emit('update:medicamento', v)}
        },
    },
    watch: {
        // Vigila un cambio en la unidad para iniciar la busqueda
        queryMed(){
            this.page = 1
            this.cargaMedicamentos()
        },
        page(){
            this.cargaMedicamentos()
        }
    },

}
</script>

<style>

</style>